<template>
  <div class="bg-gray-100">
    <div class="max-w-4xl mx-auto py-32 px-4 sm:px-6 lg:px-8">
      <p
        class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide"
      >
        Published by the UNESCO Chair in Global Adult Education, Faculty of
        Education, University of Malta, in cooperation with the International
        Council for Adult Education
      </p>
      <div class="max-w-md mx-auto mt-6 grid grid-cols-2 gap-4">
        <div class="col-span-1 flex justify-center">
          <img class="h-12" src="../assets/icae-logo.png" alt="Tuple" />
        </div>
        <div class="col-span-1 flex justify-center">
          <img class="h-12" src="../assets/uom-logo.png" alt="Mirage" />
        </div>
      </div>
    </div>
  </div>
</template>
