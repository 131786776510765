<template>
  <a
    :href="href"
    class="btn whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    href: String,
  },
};
</script>

<style scoped>
.btn {
  background-color: #333;
}
.btn:hover {
  background-color: #666;
}
</style>
