<template>
  <footer class="bg-gray-50" aria-labelledby="footer-heading">
    <div class="max-w-7xl mx-auto pb-8 px-4 sm:px-6 lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <p class="pt-8 text-base text-gray-400">ISSN: 2791-1675</p>
        <p class="pt-8 text-base text-gray-400">
          &copy; 2024 Convergence. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
