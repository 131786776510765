<template>
  <div class="bg-white">
    <Header />
    <main>
      <Hero />
      <Acknowledgements />
      <CurrentIssue
        :isCurrent="true"
        :posts="posts"
        issue="Volume 45, Issue 1"
      />
    </main>

    <Footer />
  </div>
</template>

<script setup>
import Header from "../components/Header";
import Hero from "../components/Hero";
import Acknowledgements from "../components/Acknowledgements";
import CurrentIssue from "../components/CurrentIssue";
import Footer from "../components/Footer";

const posts = [
  {
    title: "Editorial",
    href: "/vol-45-no-1-editorial.pdf",
    category: { name: "Issue", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-03-16",
    imageUrl:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "4 min",
    author: {
      name: "Peter Mayo",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `Strengthening the Marginalized Communities in Palestine through Civic Education Approaches`,
    href: "/vol-45-no-1-1.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `The Mujawarat (Neighborhood Education) is an emancipatory learning designed to enable the participants from the societies to read life through raising awareness by the use of dialogue, which is considered a process through which humans can learn the truth about their circumstances so that they can shift from a state of negative adaptation to a state of action and anticipation of opportunities. Dar al-Kalima University with the DVV International implemented a civic education approach “Mujawarat” to 35 females and 3 males from Palestinian communities. The participants were mainly learners in the community centers for adult education activities who registered aiming for personal development that enhanced employability, and community and cultural learning and participation. It is essential to engage different groups of society in the training needs identification process, take gender into consideration and bring on board experts with various experiences. Among the outcomes, new community initiatives are equally important to improve career prospects of individuals and also to strengthen local partners and increase local ownership.`,
      fr: `Le Mujawarat (éducation de quartier) est un apprentissage émancipatoire conçu pour permettre aux participants des sociétés de comprendre la vie en les sensibilisant grâce à l'utilisation du dialogue, considéré comme un processus permettant aux êtres humains d'apprendre la vérité sur leurs circonstances afin qu'ils puissent passer d'un état d'adaptation négative à un état d'action et d'anticipation des opportunités. L'université Dar al-Kalima, en collaboration avec la DVV International, a mis en œuvre une approche d'éducation civique "Mujawarat" auprès de 35 femmes et 3 hommes issus de communautés palestiniennes. Les participants étaient principalement des apprenants dans les centres communautaires pour les activités d'éducation des adultes qui se sont inscrits en vue d'un développement personnel améliorant l'employabilité, ainsi que l'apprentissage et la participation communautaires et culturels. Il est essentiel d'impliquer différents groupes de la société dans le processus d'identification des besoins en formation, de prendre en compte la dimension du genre et de faire appel à des experts ayant des expériences diverses. Parmi les résultats obtenus, les nouvelles initiatives communautaires sont tout aussi importantes pour améliorer les perspectives de carrière des individus que pour renforcer les partenaires locaux et accroître l'appropriation locale.`,
      es: `El Mujawarat (Educación Vecinal) es un aprendizaje emancipador diseñado para capacitar a los participantes de las sociedades para comprender la vida mediante la sensibilización a través del diálogo, considerado como un proceso mediante el cual los seres humanos pueden aprender la verdad sobre sus circunstancias para poder pasar de un estado de adaptación negativa a un estado de acción y anticipación de oportunidades. La Universidad Dar al-Kalima, en colaboración con DVV International, implementó un enfoque de educación cívica denominado "Mujawarat" con 35 mujeres y 3 hombres de comunidades palestinas. Los participantes eran principalmente estudiantes de los centros comunitarios para actividades de educación de adultos que se inscribieron con el objetivo de lograr un desarrollo personal que mejorara la empleabilidad, así como el aprendizaje y la participación comunitaria y cultural. Es esencial involucrar a los distintos grupos de la sociedad en el proceso de identificación de las necesidades de formación, tener en cuenta el género y contar con expertos con diversas experiencias. Entre los resultados, las nuevas iniciativas comunitarias son igualmente importantes para mejorar las perspectivas laborales de las personas y también para fortalecer a los socios locales y aumentar la apropiación local.`,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-03-16",
    imageUrl:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "20 min",
    author: {
      name: "Rose Kando",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `(Not to) Care about the future? How pedagogical authority is established within hegemony`,
    href: "/vol-45-no-1-2.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `The text explores the complex interplay between authority, hegemony, and pedagogy, drawing on various philosophical and educational perspectives. It argues that authority, often associated with hierarchical structures, is both socially constructed and contested. Pedagogical authority, particularly in shaping futures, is crucial in legitimizing social hierarchies and maintaining power dynamics. Hegemony utilizes pedagogical authority to assimilate criticism and perpetuate its dominance, transforming resistance into a source of renewal. The narrative delves into the multifaceted nature of authority, emphasizing its performative aspect and its reliance on social recognition and legitimization processes. It discusses the importance of understanding the connections between social hierarchies and pedagogical actions, especially in uncertain future contexts. The concept of pedagogical authority is reimagined as a temporally limited and democratically legitimized force that takes responsibility for fostering inclusive learning environments conducive to sustainable growth. Ultimately, the text calls for a reevaluation of pedagogical practices considering changing socio-political landscapes, advocating for a more nuanced understanding of authority and its role in shaping collective futures. `,
      fr: `Ce texte explore l'interaction complexe entre l'autorité, l'hégémonie et la pédagogie, en s'appuyant sur diverses perspectives philosophiques et éducatives. Il soutient que l'autorité, souvent associée à des structures hiérarchiques, est à la fois socialement construite et contestée. L'autorité pédagogique, en particulier celle qui consiste à façonner l'avenir, est cruciale pour légitimer les hiérarchies sociales et maintenir la dynamique du pouvoir. L'hégémonie utilise l'autorité pédagogique pour assimiler les critiques et perpétuer sa domination, transformant la résistance en source de renouveau. Le récit explore les multiples facettes de l'autorité, en soulignant son aspect performatif et sa dépendance à l'égard de la reconnaissance sociale et des processus de légitimation. Il souligne l'importance de comprendre les liens entre les hiérarchies sociales et les actions pédagogiques, en particulier dans des contextes futurs incertains. Le concept d'autorité pédagogique est réimaginé comme une force limitée dans le temps et légitimée démocratiquement qui prend la responsabilité de favoriser des environnements d'apprentissage inclusifs propices à une croissance durable. En fin de compte, le texte appelle à une réévaluation des pratiques pédagogiques en tenant compte des paysages sociopolitiques changeants, en plaidant pour une compréhension plus nuancée de l'autorité et de son rôle dans le façonnement de l'avenir collectif.`,
      es: `El texto explora la compleja interacción entre autoridad, hegemonía y pedagogía, basándose en diversas perspectivas filosóficas y educativas. Sostiene que la autoridad, a menudo asociada con estructuras jerárquicas, se construye socialmente y se cuestiona. La autoridad pedagógica, especialmente en la configuración del futuro, es crucial para legitimar las jerarquías sociales y mantener las dinámicas de poder. La hegemonía utiliza la autoridad pedagógica para asimilar las críticas y perpetuar su dominio, transformando la resistencia en fuente de renovación. La narrativa profundiza en la naturaleza polifacética de la autoridad, haciendo hincapié en su aspecto performativo y en su dependencia de los procesos de reconocimiento y legitimación social. Analiza la importancia de comprender las conexiones entre las jerarquías sociales y las acciones pedagógicas, especialmente en contextos de futuro incierto. El concepto de autoridad pedagógica se reimagina como una fuerza temporalmente limitada y democráticamente legitimada que asume la responsabilidad de fomentar entornos de aprendizaje inclusivos que conduzcan a un crecimiento sostenible. En última instancia, el texto hace un llamado a la reevaluación de las prácticas pedagógicas teniendo en cuenta los cambiantes paisajes sociopolíticos, abogando por una comprensión más matizada de la autoridad y de su papel en la configuración de futuros colectivos.`,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-03-10",
    imageUrl:
      "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "22 min",
    author: {
      name: "Janek Niggemann",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `International Comparative Adult Education: Seven reflections I expect to read in each study.`,
    href: "/vol-45-no-1-3.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `International comparative adult education has a long history and has developed standards and guidelines. But in the reality of international work researchers often have limited time and resources to go deeper into these methodological reflections, because they only periodically focus on international comparison. The following paper presents seven reflections that could help researchers to raise the quality of their work. Integrating them into research practice contributes to the advancement of international comparative adult education while upholding standards of quality and rigor.`,
      fr: `L'éducation des adultes comparée au niveau international a une longue histoire et a développé des normes et des lignes directrices. Mais dans la réalité du travail international, les chercheurs disposent souvent de peu de temps et de ressources pour approfondir ces réflexions méthodologiques, car ils ne se concentrent que périodiquement sur la comparaison internationale. Le document suivant présente sept réflexions qui pourraient aider les chercheurs à améliorer la qualité de leur travail. Leur intégration dans la pratique de la recherche contribue à l'avancement de l'éducation des adultes comparée au niveau international, tout en respectant les normes de qualité et de rigueur.`,
      es: `La educación internacional comparada de adultos tiene una larga historia y ha desarrollado normas y directrices. Pero en la realidad del trabajo internacional, los investigadores suelen disponer de poco tiempo y recursos limitados para profundizar en estas reflexiones metodológicas, ya que solo se centran periódicamente en la comparación internacional. El siguiente documento presenta siete reflexiones que podrían ayudar a los investigadores a mejorar la calidad de su trabajo. Su integración en la práctica de la investigación contribuye al avance de la educación de adultos comparada a nivel internacional, manteniendo al mismo tiempo los estándares de calidad y rigor.`,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "24 min",
    author: {
      name: "Jost Reischmann",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `Trayectorias, retos y esperanzas. Educación a lo largo de la vida en el contexto global`,
    href: "/vol-45-no-1-4.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `In this text the authors discuss about the trajectories, challenges and hopes of Lifelong Learning from the Spanish context, but from a “glocal” perspective. Self-criticism is made about the dominant trends in Lifelong Learning practises and the construction of meaning is considered -in connection with Paulo Freire’s pedagogy- as an opportunity for the development of a critical and transformative education that responds to the challenges of the current stage of the Globalization. It is proposed to review the hegemonic pedagogies and educational action in the projects and contexts in which Lifelong Learning strategies and activities are implemented, taking as a reference for learning the experiences of social movements such as social economy and feminism, defending a dialogic education that gives value to care and the search for a “good life” for all. `,
      fr: `Dans ce texte, les auteurs discutent des trajectoires, des défis et des espoirs de l'apprentissage tout au long de la vie dans le contexte espagnol, mais dans une perspective "glocale". Une autocritique est faite sur les tendances dominantes dans les pratiques d'apprentissage tout au long de la vie et la construction du sens est considérée - en relation avec la pédagogie de Paulo Freire - comme une opportunité pour le développement d'une éducation critique et transformatrice qui répond aux défis de l'étape actuelle de la mondialisation. Il est proposé de revoir les pédagogies hégémoniques et l'action éducative dans les projets et les contextes où les stratégies et les activités d'apprentissage tout au long de la vie sont mises en œuvre, en prenant comme référence pour l'apprentissage les expériences des mouvements sociaux tels que l'économie sociale et le féminisme, et en défendant une éducation dialogique qui donne de la valeur aux soins et à la recherche d'une "bonne vie" pour tous.`,
      es: `En este texto los autores reflexionan sobre las trayectorias, retos y esperanzas de la Educación a lo Largo de la Vida (EALV) desde el contexto español, pero desde una mirada “glocal”. Se hace autocrítica sobre las tendencias dominantes en la EALV y se sitúa la construcción de sentido en conexión con la pedagogía de Paulo Freire como una oportunidad para el desarrollo de una educación crítica y transformadora que responda a los retos y desafíos de la actual etapa de la globalización. Se propone revisar las pedagogías hegemónicas y la acción educativa en los proyectos y contextos en los que se desarrolla la EALV, tomando como referencia para el aprendizaje las experiencias de los movimientos sociales como la economía social y el feminismo, apostando por una educación dialógica que otorgue valor a los cuidados y sirva a la búsqueda de una “buena vida” para todos. `,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "24 min",
    author: {
      name: "Ángel Marzo y David Abril",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title:
      "Global challenges and adult learnings for better future: some reflections",
    href: "/vol-45-no-1-5.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `Local and transnational challenges put the planet and its inhabitants at risk while existing democratic regimes are questioned by raising populist and anti-democratic initiatives and warfare attitudes. In this article, I interrogate how adult education can help change people’s minds for better and more peaceful futures, limiting attention to a few poignant challenges of the present: 1) the rise of populism and anti-democratic trends, 2) the emergence of post-truth, and 3) the pervasiveness of Artificial Intelligence and conversational agents. I then consider a few types of learning (global citizenship, digital citizenship, and dealing with Artificial Intelligence) that adults need to engage with to live with such challenges while hopefully building better and more peaceful futures. Finally, I consider how adult education is framed by current international policies and the challenges faced by adult education policy at the national level. In short, I argue for a fundamental need to re-state the social purpose of adult education for future policies to help support the learning above-mentioned.`,
      fr: `Les défis locaux et transnationaux mettent en péril la planète et ses habitants, tandis que les régimes démocratiques existants sont remis en question par l'émergence d'initiatives populistes et antidémocratiques ainsi que d'attitudes belliqueuses. Dans cet article, je m'interroge sur la manière dont l'éducation des adultes peut contribuer à changer les mentalités en vue d'un avenir meilleur et plus pacifique, en me concentrant sur quelques défis marquants du présent : 1) la montée du populisme et des tendances antidémocratiques, 2) l'émergence de la post-vérité et 3) l'omniprésence de l'intelligence artificielle et des agents conversationnels. Ensuite, j'examine plusieurs types d'apprentissage (citoyenneté mondiale, citoyenneté numérique et maîtrise de l'intelligence artificielle) que les adultes doivent acquérir pour relever ces défis tout en aspirant à construire des futurs meilleurs et plus pacifiques. Enfin, je discute du cadre de l'éducation des adultes dans le contexte des politiques internationales actuelles et des défis auxquels sont confrontées les politiques d'éducation des adultes au niveau national. En conclusion, je plaide en faveur d'une nécessité fondamentale de réexaminer l'objectif social de l'éducation des adultes afin que les politiques futures soutiennent l'apprentissage susmentionné. `,
      es: `Los desafíos locales y transnacionales ponen en peligro al planeta y a  sus habitantes, mientras que los regímenes democráticos existentes se ven cuestionados por el aumento de iniciativas populistas y antidemocráticas y de actitudes belicistas. En este artículo, me planteo cómo la educación de adultos puede contribuir a cambiar la mentalidad de las personas en pro de futuros mejores y más pacíficos, centrándome en algunos desafíos conmovedores del presente: 1) el auge del populismo y las tendencias antidemocráticas, 2) la aparición de la posverdad y 3) la omnipresencia de la inteligencia artificial y los agentes conversacionales. A continuación, examino algunos tipos de aprendizaje (ciudadanía global, ciudadanía digital y manejo de la inteligencia artificial) que los adultos necesitan para enfrentar estos retos y, al mismo tiempo, construir un futuro mejor y más pacífico. Por último, considero cómo se enmarca la educación de adultos en las actuales políticas internacionales y los retos a los que se enfrenta la política de educación de adultos a nivel nacional. En resumen, defiendo la necesidad fundamental de replantear la finalidad social de la educación de adultos para que las políticas futuras contribuyan a apoyar el aprendizaje antes mencionado. `,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "26 min",
    author: {
      name: "Marcella Milana",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title:
      "The Polycrisis and Adult Education Futures: The Transformative Work of Relationality",
    href: "/vol-45-no-1-6.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `We currently face a polycrisis, as a single macro-crisis of runaway failures related to the Earth’s natural as well as social systems, which imperil humanity’s prospects. In considering what might be required of adult educators in this historical moment, the climate crisis is described within the context of the revolt of the elites ushering in the neoliberal era. The logical consequences are a crisis of our civilizational form — the modern colonial capitalist patriarchal form. Drawing from Gramsci, we have entered an interregnum between two eras, where what exists is dying with many morbid symptoms. As we stand between these old and newly emerging stories, adult educators can take up their Great Work by composting modernity while using a transition imagination in teaching for relationality, kinship, regenerative lifeways, and commoning, establishing a global network of learning commons to meet local contextual needs, revitalizing a pluriverse of ways of knowing and being. `,
      fr: `Nous sommes actuellement confrontés à une polycrise, c'est-à-dire à une macro-crise unique d'échecs incontrôlés liés aux systèmes naturels et sociaux de la Terre, qui mettent en péril les perspectives de l'humanité. En examinant ce que l'on pourrait exiger des éducateurs d'adultes en ce moment historique, la crise climatique est décrite dans le contexte de la révolte des élites qui inaugure l'ère néolibérale. Les conséquences logiques sont une crise de notre forme de civilisation - la forme patriarcale capitaliste coloniale moderne. En s'inspirant de Gramsci, nous sommes entrés dans un interrègne entre deux époques, où ce qui existe est en train de mourir avec de nombreux symptômes morbides. Alors que nous nous trouvons entre ces histoires anciennes et les nouvelles histoires émergentes, les éducateurs d'adultes peuvent reprendre leur grand travail en compostant la modernité tout en utilisant unimagination de transition dans l'enseignement pour la relation, la parenté, les modes de vie régénératifs et la mise en commun, en établissant un réseau mondial de biens communs d'apprentissage pour répondre aux besoins contextuels locaux, en revitalisant une pluralité de façons de savoir et d'être.`,
      es: `Actualmente nos enfrentamos a una policrisis, como una única macrocrisis de fallos galopantes relacionados con los sistemas naturales y sociales de la Tierra, que ponen  en peligro las perspectivas de la humanidad. Al considerar lo que podría exigirse a los educadores de adultos en este momento histórico, la crisis climática se describe en el contexto de la revuelta de las élites que da paso a la era neoliberal. Las consecuencias lógicas son una crisis de nuestra forma de civilización: la forma patriarcal capitalista colonial moderna. Inspirándonos en Gramsci, hemos entrado en un interregno entre dos épocas, donde lo que existe se está muriendo con muchos síntomas mórbidos. A medida que nos situamos entre estas viejas y nuevas historias emergentes, los educadores de adultos pueden asumir su Gran Trabajo haciendo abono de la modernidad al tiempo que utilizan una imaginación de transición en la enseñanza para la relacionalidad, el parentesco, las formas de vida regenerativas y la puesta en común, estableciendo una red global de aprendizaje en común para satisfacer las necesidades contextuales locales, revitalizando una pluriversidad de formas de conocer y ser. `,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "38 min",
    author: {
      name: "Elizabeth A Lange",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title:
      "Environmental Adult Education (EAE): Course of Action for Pakistani Education System",
    href: "/vol-45-no-1-7.pdf",
    category: { name: "Article", href: "#" },
    description: {
      en: `The primary aim of this paper is to examine the literature regarding environmental adult education, strategies or initiatives taken to address the complex environmental challenges our world is facing today and how this knowledge can be employed in developing nations by increasing awareness. The initiatives taken globally on Environmental Adult Education (EAE) are providing evidence that they were implemented at local and global levels, and they allowed people, young adults, and communities to explore ecological concerns, participate in problem-solving, and execute necessary actions to improve the environment. This study has explored the literature regarding EAE and recommendations are made for the Pakistani higher education system. This study concludes that by increasing awareness and understanding of environmental issues, promoting sustainable behaviour, promoting critical consciousness, fostering collaboration, integrating practical experience, and encouraging active citizenship, environmental adult education can contribute to creating a more just and sustainable world. The study shows that there is plenty of room in the Pakistani education system to revolutionise, revise and upgrade traditional practices. There is a need for politicians to rethink the needs and demands of global survival. Government needs to focus more on initiating projects which can promote EAE through higher education. Furthermore, governments and governing bodies are asked to allocate sufficient funds to promote informal educational practices so that we can save the future of coming generations. `,
      fr: `L'objectif premier de cet article est d'examiner la littérature concernant l'éducation environnementale des adultes, les stratégies ou les initiatives prises pour relever les défis environnementaux complexes auxquels notre monde est confronté aujourd'hui et la manière dont ces connaissances peuvent être utilisées dans les pays en développement pour accroître la sensibilisation. Les initiatives mondiales en matière d'éducation environnementale des adultes (EAE) ont démontré leur mise en œuvre aux niveaux local et mondial, permettant ainsi aux individus, aux jeunes adultes et aux communautés d'explorer les préoccupations écologiques, de participer à la résolution des problèmes et de mettre en œuvre les actions nécessaires à l'amélioration de l'environnement. Cette étude explore la littérature relative à l'EAE et formule des recommandations pour le système d'enseignement supérieur pakistanais. Elle conclut qu'en accroissant la sensibilisation et la compréhension des questions environnementales, en promouvant un comportement durable, en encourageant la pensée critique, en favorisant la collaboration, en intégrant l'expérience pratique et en encourageant la citoyenneté active, l'éducation environnementale des adultes peut contribuer à la création d'un monde plus juste et plus durable. L'étude met en lumière la nécessité de révolutionner, de réviser et d'améliorer les pratiques traditionnelles dans le système éducatif pakistanais. Les hommes politiques doivent reconsidérer les besoins et les exigences de la survie mondiale, tandis que les gouvernements devraient se concentrer davantage sur le lancement de projets destinés à promouvoir l'EAE par le biais de l'enseignement supérieur. En outre, les gouvernements et les organes directeurs sont invités à allouer des fonds suffisants pour promouvoir les pratiques éducatives informelles, garantissant ainsi l'avenir des générations futures.`,
      es: `El objetivo principal de este trabajo es examinar la bibliografía relacionada con la educación medioambiental de adultos, las estrategias o iniciativas adoptadas para abordar los complejos desafíos ambientales que enfrenta nuestro mundo actualmente y cómo estos conocimientos pueden ser utilizados en los países en desarrollo para aumentar la conciencia ambiental. Las iniciativas globales en educación medioambiental de adultos (EAE) están demostrando que se han implementado a nivel local y mundial, permitiendo a individuos, adultos jóvenes y comunidades explorar las preocupaciones ecológicas, participar en la resolución de problemas y llevar a cabo acciones necesarias para mejorar el medio ambiente. En este estudio, se ha explorado la bibliografía relacionada con la EAE y se formulan recomendaciones para el sistema de educación superior en Pakistán. Se concluye que al aumentar la conciencia y comprensión de los problemas ambientales, promover un comportamiento sostenible, fomentar la pensamiento crítico, impulsar la colaboración, integrar la experiencia práctica y fomentar la ciudadanía activa, la educación medioambiental de adultos puede contribuir a crear un mundo más justo y sostenible. El estudio muestra que hay mucho margen en el sistema educativo pakistaní para revolucionar, revisar y mejorar las prácticas tradicionales. Es necesario que los políticos reconsideren las necesidades y demandas de la supervivencia global. Los gobiernos deben centrarse más en la implementación de proyectos que puedan promover la EAE a través de la educación superior. Además, se insta a los gobiernos y a los organismos gubernamentales a asignar fondos suficientes para promover prácticas educativas informales, asegurando así el futuro de las generaciones venideras. `,
    },
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "34 min",
    author: {
      name: "Sadia Bashir",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Srabani Maitra",
    href: "/vol-45-no-1-8.pdf",
    category: { name: "In Memoriam", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "6 min",
    author: {
      name: "Kiran Mirchandani, Hongxia Shan and Bonnie Slade",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `Ravindra Dave`,
    href: "/vol-45-no-1-9.pdf",
    category: { name: "In Memoriam", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "8 min",
    author: {
      name: "Maren Elfert",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `John Field`,
    href: "/vol-45-no-1-10.pdf",
    category: { name: "In Memoriam", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "6 min",
    author: {
      name: "Tom Schuller",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: `Essential Readings in International and Comparative Adult Education`,
    href: "/vol-45-no-1-11.pdf",
    category: { name: "Review", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "8 min",
    author: {
      name: "George K. Zarifis",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Submission Guidelines",
    href: "/vol-45-no-1-guidelines.pdf",
    category: { name: "Guidelines", href: "#" },
    description: "",
    date: "Volume 45, Issue 1",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "8 min",
    author: {
      name: " - ",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
];
</script>
