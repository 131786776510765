<template>
  <div class="flex flex-col rounded-lg shadow-lg overflow-hidden text-center">
    <div class="flex-1 bg-white flex flex-col justify-between">
      <div class="flex-1 p-6">
        <p class="text-sm font-medium text-indigo-600">
          {{ post.category.name }}
        </p>
        <div class="block mt-2">
          <h3 class="text-xl font-semibold text-gray-900">
            {{ post.title }}
          </h3>
        </div>
      </div>
      <div class="p-6 bg-white">
        <a
          v-if="post.description"
          href="#"
          @click.prevent="openModal"
          class="whitespace-nowrap text-sm font-medium text-gray-500 hover:text-gray-900 mr-8"
        >
          Abstract
        </a>
        <Button :href="post.href" target="_blank">Full Article</Button>
      </div>
      <div class="p-6 bg-gray-100">
        <div>
          <p class="text-sm font-medium text-gray-900">
            {{ post.author.name }}
          </p>
          <div class="text-sm text-gray-500">
            <span>
              {{ post.date }}
            </span>
            <span aria-hidden="true"> &middot; </span>
            <span> {{ post.readingTime }} read </span>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle as="div">
                  <h3
                    class="text-lg text-justify mb-4 font-medium leading-6 text-gray-900"
                  >
                    {{ post.title }}
                  </h3>
                  <p class="text-sm text-gray-900 mb-8">
                    {{ post.author.name }}
                  </p>
                </DialogTitle>
                <div class="mt-2">
                  <TabGroup>
                    <TabList class="flex space-x-1 rounded-xl bg-gray-100 p-1">
                      <Tab as="template" v-slot="{ selected }">
                        <button
                          :class="[
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                            'focus:outline-none',
                            selected
                              ? 'bg-white shadow'
                              : 'text-gray-100 hover:bg-white hover:text-gray-400',
                          ]"
                        >
                          Abstract
                        </button>
                      </Tab>
                      <Tab as="template" v-slot="{ selected }">
                        <button
                          :class="[
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                            'focus:outline-none',
                            selected
                              ? 'bg-white shadow'
                              : 'text-gray-100 hover:bg-white hover:text-gray-400',
                          ]"
                        >
                          Résumé
                        </button>
                      </Tab>
                      <Tab as="template" v-slot="{ selected }">
                        <button
                          :class="[
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                            'focus:outline-none',
                            selected
                              ? 'bg-white shadow'
                              : 'text-gray-100 hover:bg-white hover:text-gray-400',
                          ]"
                        >
                          Resumen
                        </button>
                      </Tab>
                    </TabList>
                    <TabPanels class="mt-2">
                      <TabPanel
                        :class="[
                          'rounded-xl bg-white p-3',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        ]"
                      >
                        <p class="text-sm text-justify text-gray-500">
                          {{ post.description.en }}
                        </p>
                      </TabPanel>
                      <TabPanel
                        :class="[
                          'rounded-xl bg-white p-3',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        ]"
                      >
                        <p class="text-sm text-justify text-gray-500">
                          {{ post.description.fr }}
                        </p>
                      </TabPanel>
                      <TabPanel
                        :class="[
                          'rounded-xl bg-white p-3',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        ]"
                      >
                        <p class="text-sm text-justify text-gray-500">
                          {{ post.description.es }}
                        </p>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div>

                <div class="mt-4 flex">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    Back
                  </button>
                  <div class="flex flex-1"></div>
                  <Button :href="post.href" target="_blank" @click="closeModal">
                    Full Article
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import Button from "@/components/Button";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}
function openModal() {
  isOpen.value = true;
}

defineProps({
  post: {
    type: Object,
    required: true,
  },
});
</script>

<style scoped></style>
