<template>
  <div class="relative">
    <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div
        :style="`background-image: url(${require('../assets/banner.png')})`"
        class="background-container relative shadow-xl sm:overflow-hidden sm:rounded-2xl"
      >
        <div class="tag-line text-md text-white text-right">
          <div class="text-right text-2xl font-extrabold tracking-tight mb-2">
            <span class="block text-grey-500"
              >The first issue of Volume 45 is now available.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-container {
  height: 500px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}
.tag-line {
  @apply absolute bottom-12 right-0 p-6 w-3/4 xl:w-1/2 text-white;
  background-color: #333;
}
</style>
