<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  setTimeout(() => {
    document.dispatchEvent(new Event("render-event"));
  }, 1000);
});
</script>
