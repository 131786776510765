<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-100">
    <!-- Header -->
    <div class="relative pb-32 bg-gray-800">
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
          alt=""
        />
        <div
          class="current-issue-mixer absolute inset-0 mix-blend-multiply"
          aria-hidden="true"
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto pb-24 pt-32 px-4 sm:px-6 lg:px-8 text-center"
      >
        <h2
          v-if="isCurrent"
          class="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl"
        >
          Latest Issue
        </h2>
        <p class="mt-6 text-xl text-gray-300">{{ issue }}</p>
      </div>
    </div>

    <!-- Overlapping cards -->
    <section
      class="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
      aria-labelledby="contact-heading"
    >
      <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
        <ArticleCard
          v-for="post in posts"
          :key="post.title"
          class="flex flex-col rounded-lg shadow-lg overflow-hidden"
          :post="post"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ArticleCard from "../components/ArticleCard";

export default {
  name: "CurrentIssue",
  props: {
    posts: Array,
    isCurrent: Boolean,
    issue: String,
  },
  components: {
    ArticleCard,
  },
};
</script>

<style scoped>
.current-issue-mixer {
  background-color: #333;
}
</style>
