import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/previous-issues",
    name: "Previous Issues",
    component: () =>
      import(
        /* webpackChunkName: "previous-issues" */ "../views/PreviousIssues.vue"
      ),
  },
  {
    path: "/editorial-board",
    name: "Editorial Board",
    component: () =>
      import(
        /* webpackChunkName: "editorial-board" */ "../views/EditorialBoard.vue"
      ),
  },
  {
    path: "/submission-guidelines",
    name: "Submission Guidelines",
    component: () =>
      import(
        /* webpackChunkName: "submission-guidelines" */ "../views/SubmissionGuidelines.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
